import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AirportTransfer.module.css";
import StartPopup from "./Popup/StartPopup";
import ContactInformation from "./Popup/ContactInformation";

function BookingForm4() {
  const [isStartPopupOpen, setIsStartPopupOpen] = useState(false);
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(false);
  const [pickupAddress, setPickupAddress] = useState("");
  const [destination, setDestination] = useState("");
  const [pickupDay, setPickupDay] = useState(new Date()); 
  const [pickupTime, setPickupTime] = useState("05:00");
  const [returnDay, setReturnDay] = useState(new Date()); 
  const [returnTime, setReturnTime] = useState("05:00"); 
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedCarTypeId, setSelectedCarTypeId] = useState();

  useEffect(() => {
    const fetchCarTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/car-type`
        );
        const data = await response.json();
        setCarTypes(data.data.car_type_list);
      } catch (error) {
        console.error("Error fetching car types:", error);
      }
    };

    fetchCarTypes();
  }, []);

  const handleCloseStartPopup = (addressData) => {
    setIsStartPopupOpen(false);
    if (addressData) {
      setPickupAddress(addressData.fullAddress);
    }
  };

  const handleCarTypeChange = (event) => {
    setSelectedCarType(event.target.value);
    const carTypeId = carTypes.find(
      (carType) => carType.name === event.target.value
    )?.name_id;
    setSelectedCarTypeId(carTypeId);
    console.log(carTypeId);
  };

  const payload = `Đưa đón Villa\n- Điểm đón: ${pickupAddress}\n- Tên villa: ${destination}\n- Loại xe: ${selectedCarType}\n- Giờ đón: ${pickupTime}\n- Ngày đón: ${pickupDay.toISOString().split('T')[0]}\n- Giờ về: ${returnTime}\n- Ngày về: ${returnDay.toISOString().split('T')[0]}`;


  const timeOptions = [
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
  ];

  return (
    <>
      {isContactInfoOpen ? (
        <ContactInformation
          onBackToBooking={() => {
            setIsContactInfoOpen(false);
          }}
          payload={payload}
        />
      ) : (
        <>
          {isStartPopupOpen && (
            <StartPopup onClosePopup={handleCloseStartPopup} />
          )}
          <form
            className={styles.bookingForm}
            onSubmit={() => {
              setIsContactInfoOpen(true);
            }}
          >
            <h2 className={styles.formTitle}>Đưa đón villa</h2>

            <label htmlFor="pickupPoint" className={styles.formLabel}>
              Điểm đón :
            </label>
            <input
              id="pickupPoint"
              type="text"
              className={styles.formInput}
              value={pickupAddress}
              onClick={() => {
                setIsStartPopupOpen(true);
              }}
              placeholder="VD: 40 Tạ Quang Bửu, Hai Bà Trưng, Hà Nội"
            />

            <label htmlFor="destination" className={styles.formLabel}>
              Tên villa :
            </label>
            <input
              id="destination"
              type="text"
              className={styles.formInput}
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              placeholder="VD: Mon Villa, đồi Monaco, TP Hạ Long"
            />

            <label htmlFor="carType" className={styles.formLabel}>
              Loại xe :
            </label>
            <select
              id="carType"
              className={styles.formSelect}
              value={selectedCarType}
              onChange={handleCarTypeChange}
            >
              <option value="" disabled>
                Chọn loại xe
              </option>
              {carTypes.map((car) => (
                <option key={car.id} value={car.name}>
                  {car.name}
                </option>
              ))}
            </select>
            {/* {selectedCarTypeId && selectedCarTypeId !== 1 && (
              <p className={styles.note}>Loại xe không được hỗ trợ</p>
            )} */}

            <label htmlFor="pickupDay" className={styles.formLabel}>
              Ngày đón :
            </label>
            <DatePicker
              selected={pickupDay}
              onChange={(date) => setPickupDay(date)}
              dateFormat="dd/MM/yyyy"
              className={styles.formInput}
            />

            <label htmlFor="pickupTime" className={styles.formLabel}>
              Giờ đón :
            </label>
            <select
              id="pickupTime"
              className={styles.formSelect}
              value={pickupTime}
              onChange={(event) => {
                setPickupTime(event.target.value);
              }}
            >
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>

            <label htmlFor="pickupDay" className={styles.formLabel}>
              Ngày về :
            </label>
            <DatePicker
              selected={returnDay}
              onChange={(date) => setReturnDay(date)}
              dateFormat="dd/MM/yyyy"
              className={styles.formInput}
            />

            <label htmlFor="pickupTime" className={styles.formLabel}>
              Giờ về :
            </label>
            <select
              id="pickupTime"
              className={styles.formSelect}
              value={returnTime}
              onChange={(event) => {
                setReturnTime(event.target.value);
              }}
            >
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
            <p className={styles.formNote}>
              Lưu ý : Nếu quý khách có yêu cầu đặt biệt thì có thể chọn chức
              năng tư vấn đặt xe hoặc chờ chăm sóc khách hàng liên hệ xác nhận
              đơn.
            </p>
            <button type="submit" className={styles.submitButton}>
              YÊU CẦU BÁO GIÁ
            </button>
          </form>
        </>
      )}
    </>
  );
}

export default BookingForm4;
