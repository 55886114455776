import { Select } from 'antd';
import './Copyright.css';

export default function Copyright() {
  const language = localStorage.getItem('language');

  const ChangeLanguage = (value) => {
    localStorage.setItem('language', value);
    window.location.reload();
  }

  return(
    <div className="copyright-layer">
      <div className='copyright-wrapper'>
        <Select
          className='select-language'
          onChange={ChangeLanguage}
          defaultValue={language === '1' ? 1 : 0}
          options={[
            { value: 0, label: 'Tiếng Việt' },
            { value: 1, label: 'English' }
          ]}
        />
        <p>Copyright © 2023 Pippip. All rights reserved</p>
      </div>
    </div>
  )
}