import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import  { useParams } from 'react-router-dom';

import './OrderDetail.css';
import ContractItem from "./Item/ContractItem";
import OrderItem from "./Item/OrderItem";
import {
  payment_method,
  payment_method_en,
  payment_status,
  payment_status_en,
  payment_type,
  payment_type_en,
  ServiceList,
  ServiceList_en
} from './data';

// props of component:
//    - data: data about contract
//    - setOrderCode: set order code for check order page
//    - type: true is combo contract, false is order airport
export default function OrderDetail({ data, setOrderCode, type }) {
  const { orderCode } = useParams();
  const navigate = useNavigate();

  const language = localStorage.getItem('language');
  const numerical_order_en = ['First', 'Second'];
  // var data = props.orderDetail?.comboContract ? props.orderDetail?.comboContract : null;

  useEffect(() => {
    if(!data) {
      setOrderCode(orderCode);
      navigate('/checkdonhang');
    }
  });

  const FormatMoney = (money) => {
    let formatMoney = money.toLocaleString('vi', {style : 'currency', currency : 'VND'}).slice(0, -2)
    
    return formatMoney;
  }

  if (!data) {
    return;
  }
  else if (language === '1') return(
    <div className="order-detail component-layout">
      <div className="order-detail-wrapper component-wrapper">
        <div className="order-info info-box">
          <div className="info-title">
            <h1>Order Code: {orderCode}</h1>
            <h2>
              {`${type? data?.customer?.customer_name : data?.name} `} 
              -
              {` ${type? data?.customer?.customer_phone : data?.phone}`}
            </h2>
          </div>
          <div className="info-detail">
            {type?
              data?.contract_list.map((e, index) =>
                e.contract_status < 4 ? 
                <ContractItem key={index} contract={e} index={index}/>
                :
                null
              )
              :
              <OrderItem order={data} />
            }
            <h2 className="subtitle">
              Payment&nbsp;
              {payment_type_en[data?.payments?.type]}
            </h2>
            <ul>
              {type? 
                data?.payments?.stage?.map((e, index) =>
                  <li key={index}>
                    {numerical_order_en[index]}: {FormatMoney(e.amount)} VNĐ
                    - {payment_method_en[e.payment_method]} 
                    &nbsp;- {payment_status_en[e.payment_status]}
                  </li>
                )
                :
                <li>
                  First: {FormatMoney(data.price)} VNĐ 
                  - {payment_method_en[data?.payment_method]}
                  &nbsp;- {payment_status_en[data?.payment_status]}
                </li>
              }
              {type && data?.payments?.vat ?
                <li>Has bill</li>
                :
                !type && data?.vat ?
                  <li>Has bill</li>
                  :
                  null
              }
            </ul>
          </div>
        </div>
        <div className="service-info info-box">
          <div>
            <h1>Bank Information</h1>
            <ul>
              <li>Bank name : VIB</li>
              <li>Account Number : 298336699</li>
              <li>Account Name : Trần Ngọc Hiếu</li>
              <li>Content: {orderCode}</li>
              {type ?
                data?.payments?.stage?.length === 1 ?
                  <li>
                    Amount: {FormatMoney(data?.payments?.stage[0]?.amount)} VNĐ
                  </li>
                  :
                  null
                :
                <li>
                  Amount: {FormatMoney(data?.price)} VNĐ
                </li>
              }
            </ul>
          </div>
          {ServiceList_en.map((e, index) =>
            <div key={index}>
              <h1>{e.title}</h1>
              {e.subtitle.map((subtitle, index2) =>
                <p key={index2 * 5}>{subtitle}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  else return (
    <div className="order-detail component-layout">
      <div className="order-detail-wrapper component-wrapper">
        <div className="order-info info-box">
          <div className="info-title">
            <h1>Mã đơn hàng: {orderCode}</h1>
            <h2>
              {`${type? data?.customer?.customer_name : data?.name} `} 
              -
              {` ${type? data?.customer?.customer_phone : data?.phone}`}
            </h2>
          </div>
          <div className="info-detail">
            {type?
              data?.contract_list.map((e, index) =>
                e.contract_status < 4 ? 
                <ContractItem key={index} contract={e} index={index}/>
                :
                null
              )
              :
              <OrderItem order={data} />
            }
            <h2 className="subtitle">
              Thanh toán&nbsp;
              {payment_type[data?.payments?.type]}
            </h2>
            <ul>
              {type? 
                data?.payments?.stage?.map((e, index) =>
                  <li key={index}>
                    Lần {index + 1}: {FormatMoney(e.amount)} VNĐ
                    - {payment_method[e.payment_method]} 
                    &nbsp;- {payment_status[e.payment_status]}
                  </li>
                )
                :
                <li>
                  Lần 1: {FormatMoney(data.price)} VNĐ 
                  - {payment_method[data?.payment_method]}
                  &nbsp;- {payment_status[data?.payment_status]}
                </li>
              }
              {type && data?.payments?.vat ?
                <li>Có hóa đơn</li>
                :
                !type && data?.vat ?
                  <li>Có hóa đơn</li>
                  :
                  null
              }
            </ul>
          </div>
        </div>
        <div className="service-info info-box">
          <div>
            <h1>THÔNG TIN CHUYỂN KHOẢN</h1>
            <ul>
              <li>Ngân hàng : VIB</li>
              <li>Số tài khoản : 298336699</li>
              <li>Tên tài khoản : Trần Ngọc Hiếu</li>
              <li>Nội dung chuyển khoản: {orderCode}</li>
              {type ?
                data?.payments?.stage?.length === 1 ?
                  <li>
                    Số tiền chuyển khoản: {FormatMoney(data?.payments?.stage[0]?.amount)} VNĐ
                  </li>
                  :
                  null
                :
                <li>
                  Số tiền chuyển khoản: {FormatMoney(data?.price)} VNĐ
                </li>
              }
            </ul>
          </div>
          {ServiceList.map((e, index) =>
            <div key={index}>
              <h1>{e.title}</h1>
              {e.subtitle.map((subtitle, index2) =>
                <p key={index2 * 5}>{subtitle}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}