import React, { useState } from "react";
import { FaCopy } from "react-icons/fa"; 
import styles from "./AirportTransfer.module.css";
import { useNavigate } from "react-router-dom";

function SuccessOrder({ code }) {
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(code).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset the copy status after 2 seconds
    });
  };

  return (
    <div className={styles.bookingForm}>
      <h1 className={styles.heading}>Đặt hàng thành công</h1>
      <h3>
        Mã đơn hàng: {code}{" "}
        <FaCopy
          className={styles.copyIcon}
          onClick={handleCopyClick}
          title="Copy to clipboard"
        />
      </h3>
      {isCopied && (
        <p className={styles.copiedText}>Mã đơn hàng đã được sao chép!</p>
      )}

      <p className={styles.formNote}>Lưu ý khách hàng:</p>
      <p className={styles.smallerFormNote}>
        • Theo dõi đơn hàng trên website bằng tính năng check đơn hàng.
      </p>
      <p className={styles.smallerFormNote}>
        • Để kiểm tra thông tin đơn hàng, quý khách cần nhập thông tin mã đơn
        hàng cùng số điện thoại đặt hàng.
      </p>
      <p className={styles.smallerFormNote}>
        • Các thông tin hỗ trợ khách hàng được cung cấp đầy đủ trong trang thông
        tin đơn hàng như thông tin chuyển khoản, thông tin lái xe đưa đón,
        hotline hỗ trợ và lưu ý về chuyến đi cho khách hàng.
      </p>

      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={styles.submitButton}
          onClick={() => {
            navigate(`/checkdonhang`);
          }}
        >
          Kiểm tra đơn hàng vừa đặt
        </button>
        <button
          type="button"
          className={styles.backButton}
          onClick={() => {
            navigate(`/order`);
            window.location.reload();
          }}
        >
          Tiếp tục đặt xe
        </button>
      </div>
    </div>
  );
}

export default SuccessOrder;
