import { Form, Input, Button } from 'antd'
import { useState} from 'react';
// import { useNavigate } from 'react-router-dom';

import './DeleteAccount.css';

export default function DeleteAccount() {
  const [ form ] = Form.useForm();
  // const navigate = useNavigate();
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ successMessage, setSuccessMessage ] = useState('');
  const language = localStorage.getItem('language');

  const handleDeleteAccount = async (value) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/register/block`, 
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: value.phoneNumber,
                code: value.code,
                
              }),
        }
      );
      
      const result  = await res.json()
      // console.log("phản hồi: ", result);

      if(result.status == 1){
        language === '1' ? setSuccessMessage('Delete account successfully') : setSuccessMessage('Xóa tài khoản thành công');
        document.getElementById('success-layer').classList.remove('hide');
      }
      else{
        if(result.message.includes('Block failed')){
          language === '1' ? setErrorMessage('Delete account failed. Please try again!') : setErrorMessage('Xóa tài khoản thất bại. Vui lòng thử lại!');
        } else if(result.message.includes('The customer is not exist')){
          language === '1' ? setErrorMessage('Phone number or code is incorrect') : setErrorMessage('Số điện thoại hoặc mã code không đúng');
          form.setFields([
            {
                name: 'phoneNumber',
                errors: language === '1' ? ['Phone number or code is incorrect'] : ['Số điện thoại hoặc mã code không đúng'],
            },
            {
              name: 'code',
              errors: ' ',
            },
        ]);
        } else {
          setErrorMessage(result.message);
        }
        document.getElementById('error-layer').classList.remove('hide');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Dường như đã có lỗi xảy ra');
      document.getElementById('error-layer').classList.remove('hide');
    }
  }

  const ExitErrorMessage = () => {
    setErrorMessage('');
    document.getElementById('error-layer').classList.add('hide');
  }

  const ExitSuccessMessage = () => {
      setSuccessMessage('');
      document.getElementById('success-layer').classList.add('hide');
      form.resetFields();
  }

  return(
    // todo: change class name
    <div className="delete-account component-layout">
      <div className="delete-account-wrapper component-wrapper">
        <div className="main-content">
          <div className="account-info">
            {language === '1' ?
              <>
                <h1>Delete account</h1>
                <p>Use your PHONE NUMBER and CODE to delete account</p>
              </>
              :
              <>
                <h1>Xóa tài khoản</h1>
                <p>Quý khách vui lòng nhập SỐ ĐIỆN THOẠI</p>
                <p>và MÃ CODE để thực hiện xóa tài khoản.</p>
              </>
            }
            <Form 
              className='account-info-form' 
              form={form}
              onFinish={(value) => handleDeleteAccount(value)}
            >
              <h4 className='label'>
                {language === '1' ? 'Phone number' : 'Số điện thoại'}
              </h4>
              <Form.Item
                name='phoneNumber'
                className='form-input'
                rules={[
                  {required: true, message: "Cần nhập Số điện thoại!"},
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Số điện thoại chỉ chứa số"
                  },
                ]}
              >
                <Input
                  className='account-code-input'
                  type="number" 
                  pattern="[0-9]*"
                />
              </Form.Item>
              <h4 className='label'>
                {language === '1' ? 'Code' : 'Mã code'}
              </h4>
              <Form.Item
                name='code'
                className='form-input'
                rules={[
                  {required: true, message: "Cần nhập Mã code!"},
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    message: "Mã code chỉ chứa số"
                  },
                  // {len: 11, message: "Mã đơn hàng cần đủ 11 chữ số"}
                ]}
              >
                <Input 
                  className='account-code-input'
                  type="number" 
                  pattern="[0-9]*"
                />
              </Form.Item>
              <Form.Item className='form-input'>
                <Button
                  className='form-submit-button heading-4'
                  htmlType='submit'
                >
                  {language === '1' ? 'Delete account' : 'Xóa tài khoản'}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className='mobile-app-link'>
            <a
              href='https://play.google.com/store/apps/details?id=vn.pippip'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/CheckOrder/AndroidLink.png' alt='' />
            </a>
            <a
              href='https://apps.apple.com/vn/app/pippip/id6496683066'
              target='_blank'
              rel="noreferrer"
            >
              <img src='/Image/CheckOrder/IosLink.png' alt='' />
            </a>
          </div>
        </div>
        <img id='background-image' src='/Image/CheckOrder/car-checkdonhang.png' alt=''/>
      </div>
      <div id='error-layer' className="error-layer hide">
        <div className="error-box">
          <div className='error-icon'></div>
          <div className="message-title">Xin lỗi!</div>
          <div className='message-content'>{errorMessage}</div>
          <button onClick={ExitErrorMessage} className='heading-4'>OK</button>
        </div>
      </div>
      <div id='success-layer' className="success-layer hide">
        <div className="success-box">
          <div className='success-icon'></div>
          <div className="message-title">Thông báo</div>
          <div className='message-content'>{successMessage}</div>
          <button onClick={ExitSuccessMessage} className='heading-4'>OK</button>
        </div>
      </div>
    </div>
  );
}
