import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./AirportTransfer.module.css";
import StartPopup from "./Popup/StartPopup";
import ToAirportPopup from "./Popup/ToAirportPopup";
import PriceForm from "./PriceForm";

function BookingForm2() {
  const [isStartPopupOpen, setIsStartPopupOpen] = useState(false);
  const [isToAirportPopupOpen, setIsToAirportPopupOpen] = useState(false);
  const [isPriceFormOpen, setIsPriceFormOpen] = useState(false);
  const [pickupAddress, setPickupAddress] = useState("");
  const [address, setAddress] = useState("");
  const [provinceId, setProvinceId] = useState();
  const [districtId, setDistrictId] = useState();
  const [selectedAirport, setSelectedAirport] = useState("");
  const [airportId, setAirportId] = useState();
  const [pickupDay, setPickupDay] = useState(new Date()); 
  const [pickupTime, setPickupTime] = useState("05:00"); 
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState("");
  const [selectedCarTypeId, setSelectedCarTypeId] = useState();
  const [price, setPrice] = useState(null); 

  useEffect(() => {
    const fetchCarTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/car-type`
        );
        const data = await response.json();
        setCarTypes(data.data.car_type_list);
      } catch (error) {
        console.error("Error fetching car types:", error);
      }
    };

    fetchCarTypes();
  }, []);

  const handleCloseStartPopup = (addressData) => {
    setIsStartPopupOpen(false);
    if (addressData) {
      setPickupAddress(addressData.fullAddress);
      setProvinceId(addressData.provinceId);
      setDistrictId(addressData.districtId);
      setAddress(addressData.address);
    }
  };

  const handleCloseToAirportPopup = (data) => {
    setIsToAirportPopupOpen(false);
    if (data) {
      setSelectedAirport(data.airportName);
      setAirportId(data.airportId);
    }
  };

  const handleCarTypeChange = (event) => {
    setSelectedCarType(event.target.value);
    const carTypeId = carTypes.find(
      (carType) => carType.name === event.target.value
    )?.name_id;
    setSelectedCarTypeId(carTypeId);
    console.log(carTypeId);
  };

  const payload = {
    service_id: 1,
    car_type_name_id: selectedCarTypeId,
    airport_id: airportId,
    province_id: provinceId,
    district_id: districtId,
    address: address,
    flight_number: "VN1234",
    pickup_time: `${pickupTime} ${pickupDay.toLocaleDateString("en-GB")}`, // Format time as "HH:mm dd/MM/yyyy"
  };

  const handleGetQuote = async (e) => {
    e.preventDefault();
    console.log(payload);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/first_protocol/quotation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      setPrice(data?.data?.price);
      setIsPriceFormOpen(true);
    } catch (error) {
      console.error("Error fetching price:", error);
    }
  };

  const timeOptions = [
    "01:00 ",
    "02:00 ",
    "03:00 ",
    "04:00 ",
    "05:00 ",
    "06:00 ",
    "07:00 ",
    "08:00 ",
    "09:00 ",
    "10:00 ",
    "11:00 ",
    "12:00 ",
    "01:00 ",
    "02:00 ",
    "03:00 ",
    "04:00 ",
    "05:00 ",
    "06:00 ",
    "07:00 ",
    "08:00 ",
    "09:00 ",
    "10:00 ",
    "11:00 ",
    "12:00 ",
    "13:00",
    "14:00 ",
    "15:00 ",
    "16:00 ",
    "17:00 ",
    "18:00 ",
    "19:00 ",
    "20:00 ",
    "21:00 ",
    "22:00 ",
    "23:00 ",
    "24:00 ",
  ];

  return (
    <>
      {isPriceFormOpen ? (
        <PriceForm
          price={price}
          onBackToBooking={() => {
            setIsPriceFormOpen(false);
          }}
          payload={payload}
        />
      ) : (
        <>
          {isStartPopupOpen && (
            <StartPopup onClosePopup={handleCloseStartPopup} />
          )}
          <form className={styles.bookingForm} onSubmit={handleGetQuote}>
            <h2 className={styles.formTitle}>Đi lên sân bay</h2>
            <label htmlFor="pickupPoint" className={styles.formLabel}>
              Điểm đón :
            </label>
            <input
              id="pickupPoint"
              type="text"
              className={styles.formInput}
              defaultValue={pickupAddress}
              onClick={() => {
                setIsStartPopupOpen(true);
              }}
              placeholder="VD: 40 Tạ Quang Bửu, Hai Bà Trưng, Hà Nội"
            />
            <label htmlFor="destination" className={styles.formLabel}>
              Đến sân bay :
            </label>
            <input
              id="destination"
              type="text"
              className={styles.formInput}
              value={selectedAirport}
              onClick={() => {
                setIsToAirportPopupOpen(true);
              }}
              readOnly
            />
            <label htmlFor="carType" className={styles.formLabel}>
              Loại xe :
            </label>
            <select
              id="carType"
              className={styles.formSelect}
              value={selectedCarType}
              onChange={handleCarTypeChange}
            >
              <option value="" disabled>
                Chọn loại xe
              </option>
              {carTypes.map((car) => (
                <option key={car.id} value={car.id}>
                  {car.name}
                </option>
              ))}
            </select>
            {/* {selectedCarTypeId && selectedCarTypeId !== 1 && (
              <p className={styles.note}>Loại xe không được hỗ trợ</p>
            )} */}
            <label htmlFor="pickupDay" className={styles.formLabel}>
              Ngày đón :
            </label>
            <DatePicker
              selected={pickupDay}
              onChange={(date) => setPickupDay(date)}
              dateFormat="dd/MM/yyyy"
              className={styles.formInput}
            />
            <label htmlFor="pickupTime" className={styles.formLabel}>
              Giờ đón :
            </label>
            <select
              id="pickupTime"
              className={styles.formSelect}
              value={pickupTime}
              onChange={(event) => {
                setPickupTime(event.target.value);
              }}
            >
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
            <p className={styles.formNote}>
              Lưu ý : Nếu quý khách muốn đặt xe đón nhiều điểm thì có thể chọn
              chức năng tư vấn đặt xe hoặc chờ chăm sóc khách hàng liên hệ xác
              nhận đơn.
            </p>
            <button type="submit" className={styles.submitButton}>
              BÁO GIÁ
            </button>
          </form>
        </>
      )}

      {isToAirportPopupOpen && (
        <ToAirportPopup onCloseAirportPopup={handleCloseToAirportPopup} />
      )}
    </>
  );
}

export default BookingForm2;
