import React, { useState } from "react";
import styles from "./AirportTransfer.module.css";
import SuccessOrder from "./SuccessOrder";
function PriceForm({ price, onBackToBooking, payload }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [code,setCode] = useState("");
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const handleConfirmClick = async () => {
    if (!name || !phone) {
      setError("Vui lòng nhập tên và số điện thoại.");
      return;
    }

    const finalPayload = {
      ...payload, 
      name: name,
      phone: phone,
      price: price,
      payment_method: 1,
      vat: 1,
      note: "None"
    };
    console.log(finalPayload);
    

    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/order_airport`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalPayload),
      });

      const data = await response.json();
      if (data.status===1) {
        console.log("Booking confirmed:", data);
        setCode(data.data.order_code);
        setIsSuccessOpen(true);
      } else {
        console.error("Booking failed:", data.message);
      }
    } catch (error) {
      console.error("Error confirming booking:", error);
    }
  };

  return (
    <>
    {isSuccessOpen ? (<SuccessOrder code={code}/>): (
        <div className={styles.bookingForm}>
      <label htmlFor="name" className={styles.formLabel}>
        Tên khách hàng:
      </label>
      <input
        id="name"
        type="text"
        className={styles.formInput}
        value={name}
        onChange={(e)=>{setName(e.target.value);}}
      />
      <label htmlFor="phone" className={styles.formLabel}>
        Số điện thoại liên hệ:
      </label>
      <input
        id="phone"
        type="text"
        className={styles.formInput}
        value={phone}
        onChange={(e)=>{setPhone(e.target.value);}}
      />
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.priceDisplay}>
        Báo giá:
      </div>
      <div className={styles.priceDisplay1}>
        {price} VND
      </div>
      <p className={styles.formNote}>
        Lưu ý: Nếu quý khách muốn đặt xe đón nhiều điểm thì có thể chọn chức
        năng tư vấn đặt xe hoặc chờ chăm sóc khách hàng liên hệ xác nhận đơn.
      </p>
      <div className={styles.buttonContainer}>
        <button
          type="button"
          className={styles.submitButton}
          onClick={handleConfirmClick}
        >
          Xác nhận thông tin và đặt xe
        </button>
        <button
          type="button"
          className={styles.backButton}
          onClick={onBackToBooking}
        >
          Quay lại
        </button>
      </div>
    </div>
    )}
    </>
    
  );
}

export default PriceForm;
