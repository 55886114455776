import { useRef } from 'react';
import { Carousel } from "antd";

import { Media_source } from '../HomeContent';
import {
  Text4
} from '../HomeStyle';

export default function Component2Mobile() {
  const slide = useRef(null);
  const language = localStorage.getItem('language');

  return(
    <div className="second-component-mobile">
      <Carousel
        ref={slide}
        slidesToShow={1}
        className='second-component-carousel'
      >
        {language === '1' ?
          // render for english language
          Media_source.map((e, index) =>
            <div key={index} className="second-component-item">
              <Text4 style={{ padding: '10px' }}>
                {e.EnglishText}<br />
              </Text4>
              <img className='media-mobile' src={e.src} alt='' />
            </div>
          )
          :
          // Render for vietnamese language
          Media_source.map((e, index) =>
            <div key={index} className="second-component-item">
              <Text4 style={{ padding: '10px' }}>
                {e.VietnameseText}<br />
              </Text4>
              <img className='media-mobile' src={e.src} alt='' />
            </div>
          )
        }
      </Carousel>
    </div>
  )
}