import React, { useState } from "react";
import styles from "./StartPopup.module.css";
import SuccessMessage from "./SuccessMessage/SuccessMessage";
function ContactInformation({ onBackToBooking, payload }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleConfirmClick = async () => {
    if (!name || !phone) {
      setError("Vui lòng nhập tên và số điện thoại.");
      return;
    }

    const finalPayload = {
      other: payload, 
      customer_name: name,
      customer_phone: phone,
      
    };
    console.log(finalPayload);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalPayload),
      });

      const data = await response.json();
      if (data.status===1) {
        console.log("Booking confirmed:", data);
        setIsSuccessOpen(true);

      } else {
        console.error("Booking failed:", data.message);
      }
    } catch (error) {
      console.error("Error confirming booking:", error);
    }
  };

  return (
    <>
      <div className={styles.backDrop}>
        <div className={styles.bookingForm}>
          <h2 className={styles.formTitle}>Thông tin liên hệ</h2>
      <label htmlFor="name" className={styles.formLabel}>
        Tên khách hàng:
      </label>
      <input
        id="name"
        type="text"
        className={styles.formInput}
        value={name}
        onChange={handleNameChange}
        placeholder="VD: Nguyen Van A"
      />
      <label htmlFor="phone" className={styles.formLabel}>
        Số điện thoại liên hệ báo giá:
      </label>
      <input
        id="phone"
        type="text"
        className={styles.formInput}
        value={phone}
        onChange={handlePhoneChange}
        placeholder="VD: 0123456789"
      />
      {error && <p className={styles.error}>{error}</p>}
      
      
        <button
          type="button"
          className={styles.submitButton}
          onClick={handleConfirmClick}
        >
          Xác nhận 
        </button>
        <button
          type="button"
          className={styles.backButton}
          onClick={onBackToBooking}
        >
          Quay lại
        </button>
   
    </div>
    </div>
    {isSuccessOpen && <SuccessMessage/>}

    </>
    
  );
}

export default ContactInformation;
