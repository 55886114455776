import './Layout.css';
import Header from '../Component/Header/Header';
import Copyright from '../Component/Copyright/Copyright';

export default function DeleteLayout(props) {
  return(
    <div className="check-layout">
      <Header />
      {props.children}
      <Copyright />
    </div>
  )
}