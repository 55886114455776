import React, { useState } from "react";
import styles from "./AirportTransfer.module.css";
import ServiceItem from "./ServiceItem";
import BookingForm1 from "./BookingForm1";
import BookingForm2 from "./BookingForm2";
import BookingForm3 from "./BookingForm3";
import BookingForm4 from "./BookingForm4";
import BookingForm5 from "./BookingForm5";
import BookingForm6 from "./BookingForm6";

function Order() {
  const services = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/be01e153accdf4985f1c64bf1bbd12cdc6c89ee42543a9a087e1d84b23272d56?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Đón tại Sân bay",
      form: <BookingForm1  />,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/17e2a39c0377fb69b398ea28387c66431e9ccf3ea20b3da6bb27d04f381aa008?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Đi lên Sân bay",
      form: <BookingForm2/>,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/36ec41098b51806df081f9be50c96f414f0fdf87d31fa2b88e51b16edbd4b1ea?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Xe đi Tỉnh",
      form: <BookingForm3  />,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e05fe039c4f2dc18a5a3fd985693c64c3b99211561c6720262a6ee746bfb53e1?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Đưa đón Villa",
      form: <BookingForm4 />,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6b5958c488ade4edf1d77fda9b30c29ed79adfd9c808a70dbbb138cbecd12b1b?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Đặt xe Du lịch",
      form: <BookingForm5 />,
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b279def31d1f1e9ca1ae6e3f2d82ec92bab3a227e0986fd7158e327d06cf299?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f",
      title: "Tư vấn đặt xe",
      form: <BookingForm6 />,
    },
  ];
  const [selectedService, setSelectedService] = useState(services[0].form);
  
  const handleServiceClick = (form) => {
    setSelectedService(form);
  };

  return (
    <div className={styles.airportTransfer}>
        <div className={styles.container}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f068f5aa0e4d790287ce2dd4f688601b01f301b8604d6c536154ee7f67db4db?placeholderIfAbsent=true&apiKey=5b83ff3916b1432cb6b26a724009025f"
            alt=""
            className={styles.backgroundImage}
          />
          <div className={styles.contentWrapper}>
            <div className={styles.mainContent}>
              <div className={styles.leftColumn}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.mainTitle}>ĐẶT XE PIPPIP</h1>
                </div>
                <div className={styles.servicesContainer}>
                  <div className={styles.servicesGrid}>
                    {services.map((service, index) => (
                      <ServiceItem
                        key={index}
                        icon={service.icon}
                        title={service.title}
                        onClick={() => handleServiceClick(service.form)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className={styles.rightColumn}>
                {selectedService}
              </div>
            </div>
          </div>
          <footer className={styles.footer}>
            Về chúng tôi | Chính sách tuyển dụng lái xe | Điều khoản sử dụng dịch vụ | Điều khoản nhà cung cấp | Copyright © 2022 Pippip. All rights reserved.
          </footer>
        </div>
        
    </div>
  );
}

export default Order;