import {
  service_type,
  service_type_en,
  car_type_en
} from '../data'

export default function OrderItem({ order }) {
  const language = localStorage.getItem('language');

  if (language === '1') return(
    <div style={{ marginBottom: '8px'}}>
      <h2 className="subtitle">
        {service_type_en[order?.service_id]} -
        Status: Payment to confirm order
      </h2>
      <ul>
        <li>Car type: {car_type_en[order?.car_type_name_id]},&nbsp;
        Pickup time: {order?.pickup_time}</li>
        {order?.service_id === 1?         // = 1 is go to the airport
          <>
            <li>Pick: {order?.address}</li>
            <li>Drop: Sân bay Nội Bài</li>
          </>
          :
          <>
            <li>Pick: Sân bay Nội Bài</li>
            <li>Drop: {order?.address}</li>
          </>
        }
        {order?.flight_number ?
          <li>Flight number: {order?.flight_number}</li>
          :
          null
        }
        {order?.note ?
          <li>Note: {order?.note}</li>
          :
          null
        }
      </ul>
    </div>
  )
  else return (
    <div style={{ marginBottom: '8px'}}>
      <h2 className="subtitle">
        Chuyến {service_type[order?.service_id]} -
        Trạng thái: Thanh toán để xác nhận đơn hàng
      </h2>
      <ul>
        <li>Loại xe: {order?.car_type_name}, Thời gian đón: {order?.pickup_time}</li>
        {order?.service_id === 1?         // = 1 is go to the airport
          <>
            <li>Đón: {order?.address}</li>
            <li>Đến: Sân bay Nội Bài</li>
          </>
          :
          <>
            <li>Đón: Sân bay Nội Bài</li>
            <li>Đến: {order?.address}</li>
          </>
        }
        {order?.flight_number ?
          <li>Số hiệu chuyến bay: {order?.flight_number}</li>
          :
          null
        }
        {order?.note ?
          <li>Ghi chú: {order?.note}</li>
          :
          null
        }
      </ul>
    </div>
  )
}