import React from "react";
import styles from "./AirportTransfer.module.css";

function ServiceItem({ icon, title , onClick}) {
  return (
    <div className={styles.serviceItem} onClick={onClick}>
      <img src={icon} alt="" className={styles.serviceIcon} />
      <h2 className={styles.serviceTitle}>{title}</h2>
    </div>
  );
}
export default ServiceItem;
