
import React from "react";
import styles from "./SuccessMessage.module.css";
import { useNavigate } from "react-router-dom";

const SuccessMessage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.backDrop}>
    <main className={styles.container}>
      <section className={styles.messageBox}>
        <h1 className={styles.title}>THÀNH CÔNG!</h1>
        <p className={styles.description}>
          Pippip đã nhận được yêu cầu báo giá của bạn, chúng tôi sẽ liên hệ với
          bạn sớm nhất trong giờ hành chính.
        </p>
        <button className={styles.confirmButton} onClick={()=>{navigate("/order"); window.location.reload();}} >
          OK
        </button>
      </section>
    </main>
    </div>
  );
};

export default SuccessMessage;
