import React, { useState, useEffect } from "react";
import styles from "./StartPopup.module.css";

function ToAirportPopup({ onCloseAirportPopup }) {
  const [airports, setAirports] = useState([]);
  const [selectedAirportId, setSelectedAirportId] = useState("");
  const [selectedAirportName, setSelectedAirportName] = useState("");

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/airport`);
        const data = await response.json();
        setAirports(data.data.airport_list);
        
      } catch (error) {
        console.error("Error fetching airports:", error);
      }
    };

    fetchAirports();
  }, []);

 

  const handleAirportChange = (e) => {
    const airportId = e.target.value;
    const airportName = airports.find((airport) => (airport._id) === airportId)?.name;
    
    setSelectedAirportId(airportId);
    setSelectedAirportName(airportName);
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedAirportId);
    onCloseAirportPopup({
      airportId: selectedAirportId,
      airportName: selectedAirportName,
    }); // Pass airport data back to the parent component
  };

  return (
    <div className={styles.backDrop}>
    <form className={styles.bookingForm} onSubmit={handleSubmit}>
      <label htmlFor="airport" className={styles.formLabel}>
        Sân bay:
      </label>
      <select
        id="airport"
        className={styles.formSelect}
        value={selectedAirportId}
        onChange={handleAirportChange}
      >
        <option value="">Chọn sân bay</option>
        {airports.map((airport) => (
          <option key={airport._id} value={airport._id}>
            {airport.name}
          </option>
        ))}
      </select>

      <button type="submit" className={styles.submitButton}>
        Xác nhận sân bay
      </button>
    </form>
    </div>
  );
}

export default ToAirportPopup;
