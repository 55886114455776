import React, { useState } from "react";
import styles from "./AirportTransfer.module.css";
import SuccessMessage from "./Popup/SuccessMessage/SuccessMessage";
function BookingForm6() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [other, setOther] = useState("");
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const handleConfirmClick = async () => {
    if (!name || !phone) {
      setError("Vui lòng nhập tên và số điện thoại.");
      return;
    }

    const finalPayload = {
      other: "Tư vấn đặt xe\n- Thông tin liên hệ: " + other,
      customer_name: name,
      customer_phone: phone,
    };
    console.log(finalPayload);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalPayload),
        }
      );

      const data = await response.json();
      if (data.status === 1) {
        console.log("Booking confirmed:", data);
        setIsSuccessOpen(true);
      } else {
        console.error("Booking failed:", data.message);
      }
    } catch (error) {
      console.error("Error confirming booking:", error);
    }
  };

  return (
    <>
      <div className={styles.bookingForm}>
        <h2 className={styles.formTitle}>Tư vấn đặt xe</h2>
        <label htmlFor="name" className={styles.formLabel}>
          Tên khách hàng:
        </label>
        <input
          id="name"
          type="text"
          className={styles.formInput}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="VD: Nguyen Van A"
        />
        <label htmlFor="phone" className={styles.formLabel}>
          Số điện thoại liên hệ báo giá:
        </label>
        <input
          id="phone"
          type="text"
          className={styles.formInput}
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="VD: 0123456789"
        />

        <label htmlFor="other" className={styles.formLabel}>
          Thông tin liên hệ:
        </label>
        <textarea
          id="info"
          type="text"
          className={styles.formInput}
          value={other}
          onChange={(e) => {
            setOther(e.target.value);
          }}
        />
        {error && <p className={styles.error}>{error}</p>}

        <p className={styles.formNote} id="note">
          Lưu ý : Nếu quý khách có yêu cầu đặt biệt thì có thể chọn chức năng tư
          vấn đặt xe hoặc chờ chăm sóc khách hàng liên hệ xác nhận đơn.
        </p>

        <button
          type="button"
          className={styles.submitButton}
          onClick={handleConfirmClick}
        >
          Xác nhận
        </button>
      </div>
      {isSuccessOpen && <SuccessMessage />}
    </>
  );
}

export default BookingForm6;
