import styled from "styled-components";

export const HomePageWrapper = styled.div`
  width: 100%;
  max-width: 1600px;
  padding: 0 7.142vw;

  @media (min-width: 1920px) {
    padding: 0;
  }

  @media (min-width: 1800px) and (max-width: 1919px) {
    padding: 0 3.571vw;
  }
`

export const Text1 = styled.div`
  font-size: calc(64px + (46 * ((100vw - 375px) / 1225)));
  line-height: 100%;
  height: max-content;
  min-width: 40vw;
  max-width: 50vw;
  width: max-content;

  @media (min-width: 1920px) {
    font-size: 140px;
    max-width: 800px;
    min-width: 0;
    padding-right: 100px;
  }

  @media (min-width: 1175px) and (max-width: 1919px) {
    max-width: 60vw;
    min-width: 0;
    padding-right: 11vw;
  }

  @media (min-width: 769px) and (max-width: 1174px) {
    max-width: 55vw;
    min-width: 0;
    padding-right: 12vw;
  }

  @media (max-width: 768px) {
    max-width: 85%;
    margin-right: 15%;
    margin-bottom: 20px;
  }
  
  @media (max-width: 652px) {
    max-width: 100%;
    margin-right: 0;
  }

  @media (min-width: 1200px) and (max-width: 1384px) {
    font-size: 92px;
  }

  @media (min-width: 1128px) and (max-width: 1199px) {
    font-size: 82px;
  }

  @media (min-width: 951px) and (max-width: 1127px) {
    font-size: 76px;
  }

  @media (min-width: 801px) and (max-width: 950px) {
    font-size: 68px;
  }

  @media (min-width: 769px) and (max-width: 800px) {
    font-size: 62px;
  }

  @media (min-width: 748px) and (max-width: 768px) {
    font-size: 70px;
  }

  @media (min-width: 653px) and (max-width: 747px) {
    font-size: 64px;
  }

  @media (min-width: 480px) and (max-width: 652px) {
    font-size: 58px;
  }

  @media (max-width: 480px) {
    font-size: 47px;
  }
`

export const Img1 = styled.div`
  width: 408px;
  // min-width: 28.571vw;
  aspect-ratio: auto 1/1;
  overflow: hidden;
  transition-delay: 400ms;

  @media (min-width: 1920px) {
    width: 571.424px;
  }

  @media (max-width: 768px) {
    width: 60%;
    margin-left: 40%;
  }
`

export const Img2 = styled.div`
  max-width: 408px;
  width: 27vw;
  aspect-ratio: auto 1/1.34;
  overflow: hidden;
  transition-delay: 600ms;

  @media (min-width: 1920px) {
    max-width:571.424px;
  }

  @media (max-width: 768px) {
    width: 60%;
    margin-right: 40%;
  }

  @media (min-width: 801px) and (max-width: 950px) {
    width: 30vw;
  }
`

export const Text2 = styled.div`
  font-size: calc(64px + (46 * ((100vw - 375px) / 1225)));
  line-height: 100%;
  width: 55vw;
  padding-left: 5vw;
  text-align: right;

  @media (max-width: 768px) {
    width: 85%;
    margin: 20px 0 20px 15%;
    padding: 0;
  }
  
  @media (max-width: 652px) {
    width: 100%;
    margin-left: 0;
  }

  @media (min-width: 1920px) {
    font-size: 140px;
  }

  @media (min-width: 1200px) and (max-width: 1384px) {
    font-size: 90px;
  }

  @media (min-width: 1128px) and (max-width: 1199px) {
    font-size: 82px;
  }

  @media (min-width: 951px) and (max-width: 1127px) {
    font-size: 74px;
  }

  @media (min-width: 801px) and (max-width: 950px) {
    font-size: 65px;
    width: 50vw;
  }

  @media (min-width: 769px) and (max-width: 800px) {
    font-size: 62px;
    width: 48vw;
  }

  @media (min-width: 748px) and (max-width: 768px) {
    font-size: 70px;
  }

  @media (min-width: 653px) and (max-width: 747px) {
    font-size: 64px;
  }

  @media (min-width: 480px) and (max-width: 652px) {
    font-size: 58px;
  }

  @media (max-width: 480px) {
    font-size: 47px;
  }
`

export const Text3 = styled.div`
  margin: auto 0;
  font-size: calc(48px + (16 * ((100vw - 375px) / 1225)));
  line-height: 100%;

  @media (min-width: 1920px) {
    font-size: 72px
  }


  @media (max-width: 768px) {
    width: 100%;
  }

  p {
    font-size: 24px;
    line-height: 120%;
    margin-top: 3vh;

    @media (min-width: 1920px) {
      font-size: 32px;
      line-height: 140%;
    }
  }
`

export const Text4 = styled.div`
  margin: auto 0;
  font-size: calc(48px + (16 * ((100vw - 375px) / 1225)));
  line-height: calc(48px + (16 * ((100vw - 375px) / 1225)));
  height: calc(2.5 * (48px + (16 * ((100vw - 375px) / 1225))));

  @media (min-width: 1920px) {
    font-size: 72px;
    line-height: 72px;
    height: 180px;
  }


  @media (max-width: 768px) {
    width: 100%;
  }
`