import React, { useState, useEffect } from "react";
import styles from "./StartPopup.module.css";

function StartPopup({ onClosePopup }) {
  const [provinces, setProvinces] = useState([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState(null);
  const [selectedProvinceName, setSelectedProvinceName] = useState("");
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [address, setAddress] = useState("");

  useEffect(() => {
    const fetchProvinces = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/province`);
        const data = await response.json();
        setProvinces(data.data.province_list);
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    };

    fetchProvinces();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      if (selectedProvinceId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_SERVER_URL}/api/district/province/${selectedProvinceId}`
          );
          const data = await response.json();
          setDistricts(data.data.district_list);
        } catch (error) {
          console.error("Error fetching districts:", error);
        }
      }
    };

    fetchDistricts();
  }, [selectedProvinceId]);

  const handleProvinceChange = (e) => {
    const provinceId = parseInt(e.target.value, 10);
    const provinceName = provinces.find((province) => province.id === provinceId)?.name;
    setSelectedProvinceId(provinceId);
    setSelectedProvinceName(provinceName || "");
    setSelectedDistrict(""); 
    setSelectedDistrictId(null); 
  };

  const handleDistrictChange = (e) => {
    const districtId = parseInt(e.target.value, 10);
    const districtName = districts.find((district) => district.id === districtId)?.name;
    setSelectedDistrict(districtName || "");
    setSelectedDistrictId(districtId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fullAddress = `${address}, ${selectedDistrict}, ${selectedProvinceName}`;
    onClosePopup({
      provinceId: selectedProvinceId,
      districtId: selectedDistrictId,
      address: address,
      fullAddress: fullAddress,
    });
  };

  return (
    <div className={styles.backDrop}>
    <form className={styles.bookingForm} onSubmit={handleSubmit}>
      <label htmlFor="province" className={styles.formLabel}>
        Tỉnh thành :
      </label>
      <select
        id="province"
        className={styles.formSelect}
        value={selectedProvinceId || ""}
        onChange={handleProvinceChange}
      >
        <option value="">Chọn tỉnh thành</option>
        {provinces.map((province) => (
          <option key={province.id} value={province.id}>
            {province.name}
          </option>
        ))}
      </select>

      <label htmlFor="district" className={styles.formLabel}>
        Quận huyện :
      </label>
      <select
        id="district"
        className={styles.formSelect}
        value={selectedDistrictId || ""}
        onChange={handleDistrictChange}
        disabled={!selectedProvinceId}
      >
        <option value="">Chọn quận huyện</option>
        {districts.map((district) => (
          <option key={district.id} value={district.id}>
            {district.name}
          </option>
        ))}
      </select>

      <label htmlFor="address" className={styles.formLabel}>
        Địa chỉ :
      </label>
      <input
        id="address"
        type="text"
        className={styles.formInput}
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />

      <button type="submit" className={styles.submitButton}>
        Xác nhận điểm đón
      </button>
    </form>
    </div>
  );
}

export default StartPopup;
